// Generated by Framer (6b748fa)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const enabledGestures = {HN2wSga1E: {hover: true}};

const cycleOrder = ["HN2wSga1E"];

const serializationHash = "framer-Bs6oS"

const variantClassNames = {HN2wSga1E: "framer-v-rpoxeu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transformPerspective: 1200, x: 0, y: 50}

const transition1 = {damping: 60, delay: 0.2, mass: 1, stiffness: 400, type: "spring"}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, width, ...props}) => { return {...props, CaqclVeEi: image ?? props.CaqclVeEi ?? {src: "https://framerusercontent.com/images/4V8YBUeyzwyXpfRcn0q4BvOK0.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/4V8YBUeyzwyXpfRcn0q4BvOK0.png?scale-down-to=512 512w,https://framerusercontent.com/images/4V8YBUeyzwyXpfRcn0q4BvOK0.png 784w"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, CaqclVeEi, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "HN2wSga1E", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><MotionDivWithFX {...restProps} {...gestureHandlers} __framer__animate={{transition: transition1}} __framer__animateOnce __framer__enter={animation} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-rpoxeu", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"HN2wSga1E"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"HN2wSga1E-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ek5u40"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"D7sbOkMl_"} style={{borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 608, intrinsicWidth: 784, pixelHeight: 608, pixelWidth: 784, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(CaqclVeEi)}} className={"framer-bo0emh"} data-framer-name={"Image"} layoutDependency={layoutDependency} layoutId={"Loi8l0xYP"}/></motion.div></MotionDivWithFX></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Bs6oS.framer-1wzuqpn, .framer-Bs6oS .framer-1wzuqpn { display: block; }", ".framer-Bs6oS.framer-rpoxeu { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 392px; }", ".framer-Bs6oS .framer-1ek5u40 { align-content: center; align-items: center; aspect-ratio: 1 / 1; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: var(--framer-aspect-ratio-supported, 392px); justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 100%; will-change: var(--framer-will-change-override, transform); }", ".framer-Bs6oS .framer-bo0emh { flex: none; height: 100%; left: calc(50.00000000000002% - 100% / 2); position: absolute; top: calc(50.00000000000002% - 100% / 2); width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Bs6oS.framer-rpoxeu, .framer-Bs6oS .framer-1ek5u40 { gap: 0px; } .framer-Bs6oS.framer-rpoxeu > * { margin: 0px; margin-bottom: calc(16px / 2); margin-top: calc(16px / 2); } .framer-Bs6oS.framer-rpoxeu > :first-child { margin-top: 0px; } .framer-Bs6oS.framer-rpoxeu > :last-child { margin-bottom: 0px; } .framer-Bs6oS .framer-1ek5u40 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Bs6oS .framer-1ek5u40 > :first-child { margin-left: 0px; } .framer-Bs6oS .framer-1ek5u40 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 392
 * @framerIntrinsicWidth 392
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"R6hRTYhm1":{"layout":["fixed","auto"]}}}
 * @framerVariables {"CaqclVeEi":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercNAnDu9DT: React.ComponentType<Props> = withCSS(Component, css, "framer-Bs6oS") as typeof Component;
export default FramercNAnDu9DT;

FramercNAnDu9DT.displayName = "Single Team card";

FramercNAnDu9DT.defaultProps = {height: 392, width: 392};

addPropertyControls(FramercNAnDu9DT, {CaqclVeEi: {__defaultAssetReference: "data:framer/asset-reference,4V8YBUeyzwyXpfRcn0q4BvOK0.png?originalFilename=Image+5+%287%29.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramercNAnDu9DT, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})